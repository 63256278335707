body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    overflow-x: hidden;
}

section {
    display: grid;
    place-items: center;
    height: 100vh;
    font-size: 32px;
    font-weight: bold;
}

header {
    overflow: hidden;
}

#home {
    background-color: #181818;
    margin-top: -70px;
    overflow: hidden;
    height: 100vh;

    width: 100vw;
}

#projects,
#skill,
#contact,
#about,
#experience {
    color: #eaf2fa;
    height: auto;

    width: 100vw;
    background: #181818;
    justify-content: center;
}

#contact {
    height: 100vh;
}

#projects {
    justify-content: center;
}

.nav {
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: -5px;
    z-index: 2;
    height: 70px;
    min-height: 70px;
    width: calc(100% - 1.5rem);
    background-color: #181818;
    color: #00ABE4;
    font-weight: bolder;
    padding: 0 1rem;
    box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
    overflow-x: hidden;
    overflow: hidden;
    overflow-y: hidden;
}

.nav__container {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;

}

.nav__container__actions {
    display: inline-flex;
    flex-flow: row nowrap;
    float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.logo {
    font-size: 170%;
    padding-left: 30px;
}

.logo:hover {
    cursor: pointer;
}

.resume button {
    font-weight: bolder;
    font-size: 0.9rem;
    border-radius: 20px;
    padding: 10px 25px 10px 25px;
    border: none;
    letter-spacing: 2px;
    cursor: pointer;
    position: fixed;
    transform: translate(0%, -20%);
    color: #ddeeff;
    background-color: #00ABE4;
}

.resume button:hover {
    color: #00ABE4;
    background-color: #ddeeff;
}

.active {
    font-weight: bold;
}

.resume {
    left: 140%;
    position: fixed;
}

ul {

    display: flex;
    gap: 1rem;
    font-size: 0.9rem;
    list-style: none;
    padding: 0;

}

li {
    cursor: pointer;
    padding-right: 30px;
    padding-left: 30px;
}

li:hover {
    text-decoration: underline;
}

@media screen and (max-width: 440px) {
    header {
        display: none;
    }


    .resume button {

        transform: translateX(-30%);

    }



}

@media screen and (max-width: 670px) {
    .navResponsive {
        display: none;
    }

    .resume button {

        transform: translate(50%, -40%) !important;

    }


}

@media screen and (max-width: 865px) {
    ul {
        gap: 0.5rem;
    }

    .resume button {
        transform: translate(-35%, -20%);
    }
}

@media screen and (max-width: 1569px) {
    .resume button {
        transform: translate(-35%, -20%);
    }
}

@media screen and (max-width: 1472px) {
    .resume button {
        transform: translate(-95%, -20%);
    }
}

@media screen and (max-width: 1350px) {
    .resume button {
        transform: translate(-155%, -20%);
    }
}

@media screen and (max-width: 1155px) {
    .resume button {
        transform: translate(-200%, -20%);
    }
}

@media screen and (max-width: 1034px) {
    .resume button {
        transform: translate(-115%, -20%);
    }



    li {
        padding-right: 10px;
        padding-left: 10px;
    }

}