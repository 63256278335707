.world {
    text-align: center;
    display: inline-flex;
    font-size: 150%;
    color: #0e6c9a;
}

.world a {
    text-decoration: none;
    color: #0e6c9a;
}

.title {
    font-weight: lighter;
}

@media screen and (max-width: 640px) {


    .title {
        text-align: center;
    }
}

@media screen and (max-width: 1034px) {


    .title {
        text-align: center;
    }
}