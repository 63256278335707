.big-C {
    margin-top: 70px;
}

.title {
    font-size: 115%;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: lighter;
}

.title span {
    color: #00ABE4;
}

.title hr {
    color: #00ABE4 !important;
    background-color: #00ABE4 !important;
}

.about__container {
    width: 50%;
    display: inline-flex;
    text-align: left;
    height: 25%;
    font-size: 45%;
    line-height: 1.5em;
    padding: 20px 30px 20px 30px;
    border-radius: 40px;
    font-weight: lighter;
    background-color: #000;
    /*background-color: rgb(31, 33, 33);*/
}

.about__container span {

    font-weight: bolder;
    color: #00ABE4;
    letter-spacing: 0.1em;
    line-height: 1.5em;
}

.skills__container {
    border-radius: 40px;
    padding: 20px 30px 20px 30px;
    width: 90%;
    background-color: #000;
    margin-top: 20px;
    display: inline-grid;
    text-decoration: none;
    justify-content: center;

}

.skill {
    justify-content: space-between;
    position: relative;
    width: 40%;
    border-radius: 40px;
    box-shadow: rgba(52, 187, 255, 0.4) 0px 0px 0px 2px, rgba(52, 187, 255, 0.65) 0px 4px 6px -1px, rgba(52, 187, 255, 0.08) 0px 1px 0px inset;
}

.skill p {
    margin-bottom: 10px;
    font-size: 80%;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.skills__container li {
    cursor: auto;
    text-decoration: none;

}


.skills__container p {
    font-size: 80%;

}



.skillsSub img {
    padding: 0px 0px 0px 0px;
    padding-top: 10px;
}

.skillsSub p {
    padding: 0px 0px 0px 0px;
}

@media screen and (max-width: 440px) {
    .title {
        transform: translate(-1%) !important;
    }
}

@media screen and (max-width: 640px) {
    .skills__container {
        width: 80%;


    }

    .skill img {
        width: 1.5em !important;
        height: 1.5em !important;
    }

    .skill p {
        font-size: 50%;
    }
}

@media screen and (max-width: 1034px) {
    .about__container {
        width: 75%;

    }

}