.project1,
.project2,
.project3 {
    width: 27%;
    background-color: #000;
    padding: 15px 15px 15px 15px;
    border-radius: 40px;
    margin-bottom: 7px;
    margin-top: 7px;
}

.Projects-container a {
    text-decoration: none;
}

.titlePr h2 {
    margin-top: 60px;
    font-size: 140%;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
    font-weight: lighter;
}

.Projects-container {
    display: inline-flex;
    justify-content: center;
    gap: 1.5em;
    padding-left: 20px;
    margin-right: 70px;
    margin-left: 70px;
    padding-right: 20px;


}

.project-image {
    flex: 30%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    padding-bottom: 40px;

}

.project-image img {
    border: 2px solid #0e6c9a;

    box-shadow: #0e6c9a 0px 4px 5px, #0e6c9a 0px -3px 7px, #0e6c9a 0px 3px 5px, #0e6c9a 0px 8px 7px, #0e6c9a 0px -3px 5px;
}


.project-info {
    flex: 70%;
    text-align: left;
    padding-left: 20px;
    margin: 0px 0px 0px 0px;
    position: relative;
}

.project-info h1 {
    font-size: 0.6em;
    padding-bottom: 10px;
    color: #34bbff;
    text-align: left;
    margin: 0px 0px 0px 0px;
    font-weight: lighter;
}

.project-info h1:hover {
    text-decoration: underline;
}

.project-info h3 {
    text-align: left;
    font-weight: lighter;
    color: white;
    font-size: 0.35em;
    letter-spacing: 1px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

}

.end-content {
    font-size: 0.45em;
    font-weight: lighter;
    padding-top: 0px;
    margin-top: 10px;
}

.techUsed {
    margin-top: 10%;
}

.end-content a {
    text-decoration: none;
}

@media screen and (max-width: 440px) {

    .project1,
    .project2,
    .project3 {
        width: 100% !important;
        padding-right: 10px;
    }

    .project-image img {
        margin-left: 10px;

    }
}

@media screen and (max-width: 640px) {

    .project1,
    .project2,
    .project3 {
        width: 70%;
        display: inline-block;
        align-items: center;
        padding: 10px 0px 10px 0px;

    }

    .project-info h1 {
        padding-top: 10px;
    }
}

@media screen and (max-width: 1034px) {
    .Projects-container h2 {
        margin-top: 135px;

    }

    .project1,
    .project2,
    .project3 {
        width: 90%;
        display: inline-block;
        align-items: center;
        padding: 10px 0px 10px 0px;

    }

    .project-info h1 {
        padding-top: 20px;
    }
}

@media screen and (max-width: 920px) {

    .Projects-container {
        flex-direction: column;
        align-items: center;
    }

    .project1,
    .project2,
    .project3 {
        width: 70%;
        padding-right: 10px;

    }
}