.title h1 {
    text-align: left;
    font-size: 55%;
    font-weight: lighter;
    color: #34bbff;
}

.title h2 {
    font-size: 40%;
    color: #96d6f7;
    text-align: left;
    font-weight: bolder;
}

.link {
    text-decoration: underline;
    color: #34bbff;
}

.description p {
    text-align: left;
    font-weight: lighter;
    font-size: 0.4em;
    line-height: 1.8em;
    letter-spacing: 1px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.experience-container {
    display: inline-flex;
    justify-content: center;
    gap: 1.5em;
    padding-left: 20px;
    margin-right: 70px;
    margin-left: 70px;
    padding-right: 20px;
}

span {
    color: #34bbff;
    font-weight: bolder;
    /*background-color: rgb(28, 33, 33);*/
}

.experience-1,
.experience-2,
.experience-3 {
    width: 25%;
    background-color: #000;
    padding: 15px 25px 15px 25px;
    border-radius: 40px;
    margin-bottom: 7px;
    margin-top: 7px;
}

.titleEx {
    font-size: 140%;
    font-weight: lighter;
    margin-top: 65px;
    margin-bottom: 10px;
}

@media screen and (max-width: 440px) {

    .experience-1,
    .experience-2,
    .experience-3 {
        width: 100% !important;
    }
}

@media screen and (max-width: 650px) {
    .experience-container {
        flex-direction: column;
        align-items: center;
    }

    .experience-1,
    .experience-2,
    .experience-3 {
        justify-content: center;
    }

    .title {
        transform: translate(7%);
    }

}

@media screen and (max-width: 920px) {
    .experience-container {
        flex-direction: column;
        align-items: center;
    }

    .experience-1,
    .experience-2,
    .experience-3 {
        width: 70%;
    }
}